import {
  Component,
  Input,
  ViewContainerRef,
  Output,
  EventEmitter,
  ComponentRef,
  HostBinding,
  OnInit,
  HostListener,
  ComponentFactoryResolver
} from '@angular/core';

import { NgxOverlayComponent } from './overlay.component';
import { slideAnimations } from './aside.animations';

@Component({
  selector: 'ngx-aside',
  template: `<aside [@slide]="position" *ngIf="visibleStatus" [className]="position">

    <!-- Custom Header -->
    <ng-content *ngIf="!showDefaultHeader" class="aside-title-huj" select="header">


    </ng-content>
    <!-- End Custom Header -->


    <!-- Default Header -->
    <header *ngIf="showDefaultHeader">
        <div class="aside-title">
            {{title}}
        </div>

        <div (click)="hideAside($event)" class="aside-button-close">
            &times;
        </div>

    </header>
    <!-- End Custom Header -->


    <section>
        <div class="aside-container">
            <ng-content></ng-content>
        </div>
    </section>

    <!-- Custom Footer -->
    <ng-content *ngIf="!showDefaultFooter" select="footer"></ng-content>
    <!-- End Custom Footer -->

    <!-- Default Footer -->
    <footer *ngIf="showDefaultFooter">

        <button (click)="hideAside($event)" type="button" class="btn btn-secondary btn-cancel">
         {{cancelButtonTitle}}</button>

        <button (click)="submitAside($event)" type="button" class="btn btn-primary btn-submit">{{submitButtonTitle}}</button>


    </footer>
    <!--End  Default Footer -->

</aside>`,
  styles: [`:host *{box-sizing:border-box}:host aside.right{right:0;top:0;bottom:0}:host aside.left{left:0;top:0;bottom:0}aside{will-change:opacity;display:flex;flex-direction:column;align-items:stretch;position:fixed;width:auto;max-width:50%;background-color:#fff;z-index:2;box-shadow:-6px 3px 11px 0 rgba(0,0,0,.23);padding:0 16px;height:100vh}section{overflow:auto;flex-grow:1}header{font-size:20px;display:flex;flex-direction:row;justify-content:space-between;align-items:center;width:100%;height:64px;flex-shrink:0}header .aside-button-close{width:20px;text-align:center;opacity:.8}header .aside-button-close:hover{cursor:pointer;opacity:1}footer{flex-shrink:0;border-top:1px solid #e5e5e5;display:flex;align-items:flex-start;padding:16px 0}footer button{margin-right:6px}.left footer{justify-content:flex-end}.right footer{justify-content:flex-start}:host.left aside{box-shadow:6px -1px 11px 0 rgba(0,0,0,.23)}:host.left.footer{justify-content:flex-end}`],
  animations: [slideAnimations]
})

/*

 TODO: Configurable parameters
 width
 max-width

 TODO: @OutputEvents
 @OutputFunctions ?

 ----

 */

export class NgxAsideComponent {

  @Output() public cancel: EventEmitter<any> = new EventEmitter();
  @Output() public submit: EventEmitter<any> = new EventEmitter();

  @Input() public position = 'right';
  @Input() public showOverlay = true;
  @Input() public closeOnEscape = true;

  @Input() public showDefaultFooter = true;
  @Input() public showDefaultHeader = true;

  @Input() public title = '';
  @Input() public cancelButtonTitle = 'Cancel';
  @Input() public submitButtonTitle = 'Submit';

  public visibleStatus: boolean = false;
  private rootViewContainerRef: ViewContainerRef;
  private backdrop: ComponentRef<{}>;

  constructor(private _resolver: ComponentFactoryResolver, private vcRef: ViewContainerRef) {
    this.rootViewContainerRef = vcRef;
  }

  public hideAside(event) {
    if (this.cancel.observers.length > 0) {
      this.cancel.emit(event);
    } else { // If we don`t have any subscribers
      this.hide();
    }

  }

  public submitAside(event) {
    if (this.cancel.observers.length > 0) {
      this.submit.emit();
    } else {  // If we don`t have any subscribers
      this.hide();

    }
  }

  @HostListener('document:keydown.esc', ['$event'])
  public handleEscape(event) {

    if (this.closeOnEscape) {
      event.preventDefault();
      this.hideAside(event);
    }

    return false;
  }

  public hide() {

    this.visibleStatus = false;

    if (!this.backdrop) {
      return;
    }

    this.backdrop.destroy();
    this.backdrop = void 0;

  }

  public show() {
    this.visibleStatus = true;
    this.addOverlay();
  }

  private addOverlay() {
    if (!this.backdrop && this.showOverlay) {
      const OverlayComponentFactory = this._resolver.resolveComponentFactory(NgxOverlayComponent);
      this.backdrop = this.rootViewContainerRef.createComponent(OverlayComponentFactory, 0);
    }
  }
}
